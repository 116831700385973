$white: #FDFEFF;
$whiteBlue: #f8fafc;
$black: #252936;
$red: #FF7971;
$red2: #ff928b;
$redDark: #DF6861;
$lightBlack: #02162B;
$lightBlue: #EFF4FF;
$lightBlue2: #E0E5EA;

$transDefault: 300ms;
$transFast: 150ms;
$transMedium: 600ms;
$transSlow: 1000ms;

::selection { background: $black; color: $whiteBlue; }

html, body, div, article, aside, section, main, nav, footer, header, form, fieldset, legend, pre, code, p, a, h1, h2, h3, h4, h5, h6, ul, ol, li, dl, dt, dd, blockquote, figcaption, figure, textarea, input[type="email"], input[type="number"], input[type="password"], input[type="tel"], input[type="text"], input[type="url"], .border-box { box-sizing: border-box; } html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 { margin: 0; padding: 0 } h1, h2, h3, h4, h5, h6 { font-size: 100%; font-weight: normal }

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;900&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', 'Gotham', -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  background-color: $whiteBlue;
  color: $black;
}

h1, h2, h3, h4, h5, h6{
    font-family:  'Montserrat', 'Gotham', -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-style: normal;
    color: $black;
    font-weight: 900;
}
p{
  font-family:  'Montserrat', 'Gotham', -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-style: normal;
  font-weight: 400;
  color: $black;
  line-height: 145%;
}

strong{ font-weight: 600; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container{
    height: 100%;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 auto;
    overflow: hidden;
}

.give-feedback{
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem;
  p{ margin-right: 1rem; font-size: .7rem;}
  i{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    line-height: 0;
    vertical-align: baseline;
    padding: 0;
    margin: 0;

    background-color: $whiteBlue;
    border: 1px solid $lightBlue;
    box-sizing: border-box;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    transition: all $transDefault ease-out;

    svg{ width: 20px; height: 20px; fill: $red2; transition: all $transDefault ease-out; }
    &:hover{
      cursor: pointer;
      background-color: $black;
      transform: scale(1.13);
      box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.31), 0px 2px 4px rgba(0, 0, 0, 0.32);
      svg{ fill: $whiteBlue; }
    }
    &.click{
      transform: scale(1.6);
      svg{ fill: $red !important; transform: scale(1.3);}
    }
  }
}

header{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 70px auto 50px auto;
  padding: 0 2rem;
    h2{
        font-style: normal;
        font-weight: 800;
        font-size: 4rem;
        color: $black;
        margin-bottom: .7rem;
      }
      p.excerpt{
        color: $black;
        font-size: 1.2rem;
        max-width: 550px;
        width: 100%;
        line-height: 160%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

         &::after{ 
          content: "";
          position: relative;
          max-width: 100px;
          width: 100%;
          height: 4px;
          background-color: $lightBlue2;
          margin-top: 50px;
        }
    }
    
    .submit-idea{
      display: flex;
      flex-direction: column;
      position: relative;
      margin-top: 2rem;
      width: 100%;
      max-width: 500px;
      p{ margin-bottom: 1rem; font-size: 1.2rem; }

      #yourIdeaForm{ width: 100%; display: flex; flex-direction: column; position: relative; }
      .input--error{ font-size: .8rem; display: flex; justify-content: center; position: absolute; top: 120%; margin: 0; width: 100%; opacity: 0; transition: opacity $transDefault ease; &.show{ opacity: 1; }}
      input{
        width: 100%;
        background-color: $white;
        color: $black;
        border: 1px solid $lightBlue2;
        padding: 1rem;
        padding-right: 7rem;
        box-sizing: border-box;
        border-radius: 33px;
        outline: none;
        transition: all $transDefault ease;
        &.error{ border-color: $red; border-width: 2px; }
      }
      .btn--custom{
        position: absolute;
        right: 10px;
        top: 7px;
        margin: 0;
        padding: .8rem 1.7rem .76rem 1.7rem;
      }
    }
}

.card-container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: stretch;
  flex: 1;
  transform: translateY(15px);
  margin: 0 auto;
  padding: 3rem 3rem 5rem 3rem;
  border: 2px solid $lightBlue;
  box-sizing: border-box;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 9px;
  max-width: 95%;
  width: 100%;
  height: auto;
  min-height: 300px;


  .card{
    position: relative;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 400px;
    min-width: 230px;
    max-height: 500px;
    margin-right: 2rem;
    margin-bottom: 2rem;
    flex: 1;
    text-decoration: none;
    align-self: auto;

    background: $white;
    border: 2px solid $lightBlue;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    transition: all $transSlow ease;

    &:last-of-type{ margin-right: 0; }
    &:not(.no--hover):hover{
      transform: scale(1.02);
      box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1), 0px 5px 6px rgba(0, 0, 0, 0.1);
    }

    .content-container{
      position: relative;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.3rem;
    }

    &.card--comingMore{
      margin-left: 2rem;
      background-color: #f5f6f9;
      border: dashed 2px $lightBlue2;
      box-shadow: none;
      min-height: 150px;
      .content-container{
        width: 100%;
        text-align: center;
        align-self: center;
        justify-self: center;
        align-items: center;
        justify-content: center;
        p{ color: darken($lightBlue2, 10%); font-weight: bolder; }
        svg{ fill: darken($lightBlue2, 10%); }
        i{ width: 24px; height: 24px; fill: $black; margin-bottom: 1rem; }
      }
    }

    .image{
      border-radius: 9px 9px 0 0;
      width: 100%;
      display: flex;
      min-height: 150px;
      height: 200px;
      // height: 100%;
      // flex: 2;
      background-size: cover;
      background-position: center;
    }
    .btn--hot{ position: absolute; margin: 1.3rem; }
    .content{
      padding: 1.3rem;
      padding-top: 1rem;
      margin-top: 0;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      .information{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: auto;
        h4{
          font-weight: bold;
          font-size: 1.1rem;
          line-height: 113%;
          margin-bottom: .3rem;
        }
        p{ font-size: .9rem; margin-bottom: .6rem;}
      }
      .tags{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex: 1;
        ul{ justify-content: flex-end; }
        ul li{ margin: 0 5px;}
      }
    }
  }
}

.btn{
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin: 1rem 0;

  
  font-size: .8rem;
  border-radius: 100px;
  text-decoration: none;
  font-weight: bolder;
  padding: .5rem 1.5rem;
  max-width: 300px;
      
  line-height: 100%;
  vertical-align: middle;


  border: 1px solid $lightBlack;
  box-sizing: border-box;
  transition: all $transDefault ease;

  list-style: none;
  a{ text-decoration: none; }

  &--filled{
    background-color: $black;
    color: $whiteBlue;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.1);
    &:not(.btn--no-click):hover{
      background-color: $white;
      color: $black;
      p{ color: $black; }
    }
  }
  &--outline{
    background-color: none;
    color: $black;
    &:not(.btn--no-click):hover{
      background-color: $black;
      color: $white;
      p{ color: $white; }
    }
  }
  &--small{
    font-size: .6rem;
    padding: .3rem 1rem;
  }
  &--secondary{
    border-color: $lightBlue2;
    color: $lightBlack;
  }
  &--red{
    border-color: $redDark;
    color: $white;
    background-color: $red;
  }
  &--no-click{
    cursor: default;
  }
}

input{
  font-family:  Gotham, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1em;
  &::placeholder{ color: rgb(154, 164, 175); }
}

button{
  &:hover{ cursor: pointer; }
  &:focus {outline:0;}
}


.modal-container{
  display: flex;
  background-color: rgba($white, $alpha: .7);
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  transition: all $transMedium ease;
  padding: 4rem;
  transform: translateY(0px);
  &:hover{ cursor: pointer;}

  &.removing{ opacity: 0; user-select: none; pointer-events: none; transform: translateY(-80px); }
  &.hidden{ opacity: 0; user-select: none; pointer-events: none; transform: translateY(80px); }

  .content{
    display: flex;
    flex-direction: column;
    background-color: $whiteBlue;
    padding: 5rem;
    box-shadow: 0px 3px 35px rgba(0, 0, 0, 0.1), 0px 1px 6px rgba(0, 0, 0, 0.2);
    border: solid 3px $lightBlue;
    z-index: 999999;
    &:hover{ cursor: default;}

  }
  
}

.flex{
  display: flex;
}

#app--notifications {
  width: 100%;
  min-width: 150px;
  max-width: 500px;
  height: auto;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 2.5rem;
  right: 0;
  z-index: 1000;
  pointer-events: none;

  transition: opacity $transMedium ease;
  .notification {
    pointer-events: all;
    line-height: 130%;
    position: relative;
    max-width: 95%;
    width: 100%;
    opacity: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    color: $black;
    padding: 0.8rem;
    box-shadow: 2px 4px 10px rgba(32, 58, 203, 0.13), 0px 0px 4px rgba(32, 58, 203, 0.05);
    border-radius: 10px;
    margin-bottom: 2rem;
    transform: translateY(50px);
    transition: all $transMedium ease;
    border-bottom: solid 3px $lightBlack;
    font-weight: 700;
    z-index: 99999;

    span{font-weight: bolder;}
    &.type-general{  p{ font-weight: 400;}}
    &.type-hint{ border-color: $lightBlue2; p{ font-weight: 400;}}

    &.show{
      opacity: 1;
      transform: translateY(0);
    }
    &.remove{
      opacity: 0;
      transform: translateY(-50px);
    }

    .content {
      display: flex;
      align-items: center;
      width: 85%;

      p {
        font-size: 1rem;
        padding-left: 1rem;
      }

      .img {
        background-size: cover;
        font-size: 2rem;
        padding: .5rem;
        img {
          width: 100%;
          overflow: hidden;
        }
      }
    }

    .close {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
      background-color: $lightBlue2;
      background-size: cover;
      border-radius: 100px;
      transition: color $transDefault ease, background-color $transDefault ease;

      &::before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 3.6 3.2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.19858 0.873566L2.61736 0.292343L1.74548 1.16422L0.873643 0.292387L0.292419 0.87361L1.16426 1.74545L0.292466 2.61724L0.873689 3.19846L1.74548 2.32667L2.61731 3.1985L3.19853 2.61728L2.3267 1.74545L3.19858 0.873566Z' fill='%23131415'/%3E%3C/svg%3E%0A");
        background-position: center;
      }

      &:hover {
        cursor: pointer;
        background-color: $red;

        &::before {
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 3.6 3.2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.19858 0.873566L2.61736 0.292343L1.74548 1.16422L0.873643 0.292387L0.292419 0.87361L1.16426 1.74545L0.292466 2.61724L0.873689 3.19846L1.74548 2.32667L2.61731 3.1985L3.19853 2.61728L2.3267 1.74545L3.19858 0.873566Z' fill='%23FAFAFA'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }
}













@media all and (max-width: 767px) {
  .give-feedback{ display: none;}
  header{ margin: 40px auto 30px auto; }
}

@media all and (max-width: 639px) {
  .card-container{ flex-direction: column;}
  .card-container .card{ max-width: 100%; width: 100%; margin-right:0; }
  .card-container .card.card--comingMore { margin-left: 0;}

  .modal-container{ text-align:  center; line-height: 160%; padding: 2rem; }

  #app--notifications{
    max-width: 100%;
    padding: 1rem;
    justify-content: center;
    align-items: center;
  }
}

@media all and (max-width: 424px) {
  #app--notifications{ .notification .content p{font-size: .8rem;} }
}